<template>
    <div class="screen3"> 
        <div class="d-flex">
            <div class="form_box">
                <div class="blue_title">
                    EXPERIENCES BY TRAVELZONE
                    <span>Mucho más que un viaje…</span>
                </div>
                <div class="black_box">
                    <img src="../assets/img/logo.png" class="logo" alt="">

                    <p>
                        Ahora solo tienes que llamar para activar tu certificado <span>EXPERIENCES</span> al teléfono
                        <a href="tel:3320023675" class="button_privacy"><b class="label_phone">33 200 23675</b></a>                    
                        donde uno de nuestros agentes validará tu información, confirmado y agendando una cita para la entrega de tu certificado a través del número de reservación que te proporcionaremos.
                    </p>
                    <img src="../assets/img/check.png" class="check" alt="">
                    <img src="../assets/img/stars.png" class="stars" alt="">
                </div>
            </div>
            <button class="btn_capsul green">registro exitoso</button>
            <!--<div class="form_privacy">
              <img @click="policy()" src="../assets/img/banner.png" class="privacy" alt="">
            </div>-->
        </div>
    </div>
</template>


<style scoped>
.screen3{
    background: url(../assets/img/screen3.png) no-repeat;
    background-size: cover;
    height: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.blue_title span {
    text-transform: capitalize;
    display: block;
    font-family: "Amelia-Basic-Italic";
}
p{
    font-family: "Amelia-Basic-Italic";
    text-align: justify;
    max-width: 280px;
    line-height: 20px;
    margin: 30px auto;
}
p span{
    color: #31affa;
    font-family:"Amelia-Basic-BoldItalic"
}
.black_box p b{
    font-size: 24px;
    display: block;
    text-align: center;
    line-height: 30px;
  font-family:"Amelia-Basic-Black"
}
.check{    
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
}
.stars{
    max-width: 170px;
    margin: 20px auto 0;
    display: block;
}
@media(max-width:767px){
  .screen3 {
    padding: 20px 0;
    height: 100%;
  }
}
@media(max-height:600px){
    .screen3 {
        height: 100%;
    }
}
</style>


<script>
import axios from "axios";
export default {
    name:"Screen3",
    mounted() {
        this.sendEmail();
    },
    methods:{
        sendEmail() {
            console.log('send email');
            this.postVars = {
                step1_city:         localStorage.getItem('city'),
                step1_fullname:     localStorage.getItem('fullname'),
                step1_email:        localStorage.getItem('email'),		
                step1_mobile:	    localStorage.getItem('mobile'),
                step1_age:          localStorage.getItem('age'),	
                step2_marital:      localStorage.getItem('marital'),	
                step2_frequency:    localStorage.getItem('frequency'),	
                step2_vacational:   localStorage.getItem('vacational')
            };

            //this.endpoint = 'http://localhost/TRAVELZONE-LANDING-PAGES/api/sendEmail.php';
            this.endpoint = 'api/sendEmail.php';

            axios.post(this.endpoint, this.postVars)
            .then(function (response) {
                console.log('success');
                console.log(response);
            })
            .catch(function (error) {
                console.log('error');
                console.log(error);
            });
        },
        policy() {
            var city = localStorage.getItem('city');
            switch (city) {
                case 'tijuana':
                    window.location.href='http://www.conocenos.travelzone.com.mx/premio-comida/';
                    break;
                case 'cdmx':
                    window.location.href='http://www.conocenos.travelzone.com.mx/8875-2/';
                    break;
                case 'gdl':
                    window.location.href='http://www.conocenos.travelzone.com.mx/premio-hospedaje-aria-ocean/';
                    break;
                case '':
                    this.$router.push('/?city='+this.queryText)
                    break;
            }
        }
    }
}
</script>